<template>
  <div class="chatbot">
    <!-- 內嵌版本 -->
    <div v-if="type === 'embed'" class="chatbot-container embed">
      <div class="chatroom">
        <div class="navgation-bar">
          <v-icon icon="forum" color="primary" size="16" />
          <div class="title">
            <div class="font-body-2 bold">智能客服</div>
            <p v-if="isExpanded" class="font-caption subtitle">由 AI 統整生成，恕不保證資訊正確性</p>
          </div>
          <v-btn v-if="isExpanded" color="#F5F5F5" flat size="small" @click="toggleEmbedChat(false)">收起</v-btn>
        </div>
        <div v-show="isExpanded" class="content" ref="contentListRef">
          <ChatbotMessage v-for="(message, index) in chatLog.data"
            :message="message"
            :on-more-action="onSendMessage"
            :on-speak="onSpeak" />
          <div class="shortcuts"
            v-if="recommandMenu.isShowRecommandMenu">
            <ChatbotRecommandMenu :required-permission="recommandMenu.isSimpleRecommadMenu" :onPress="onSendMessage"
              :onMorePress="() => toggleMenu(true)"></ChatbotRecommandMenu>
          </div>
          <!-- Loading -->
          <ChatbotMessage
            v-if="isLoadingResponse"
            is-loading-response
            :message="loadingMessage" />
        </div>
        <div class="toolbar">
          <ChatbotInputBar
            v-if="isExpanded"
            simple
            :onSendMessage="onSendMessage" />
          <ChatbotInputBar v-else simple @click="toggleEmbedChat(true)" />
        </div>
      </div>
    </div>

    <!-- 一般/全屏版本 -->
    <div v-else class="chatbot-container" :class="{ normal: type === 'normal', full: type === 'full' }">
      <div class="chatroom">
        <div class="navgation-bar">
          <v-btn v-if="type === 'full'" icon="arrow_back" color="rgba(0, 0, 0, 0.54)" variant="text" size="36" @click="onBack" />
          <v-icon v-else icon="forum" color="primary" />
          <div class="title">
            <h6 class="bold">智能客服</h6>
            <p class="font-caption subtitle">由 AI 統整生成，恕不保證資訊正確性</p>
          </div>
          <v-btn icon="report" color="rgba(0, 0, 0, 0.54)" variant="text" size="36" @click="toggleReport(true)" />
          <v-btn v-if="type === 'normal'"
            icon="close"
            color="rgba(0, 0, 0, 0.54)"
            variant="text"
            size="36"
            class="ml-1"
            @click="onBack" />
        </div>
        <div class="content" ref="contentListRef">
          <ChatbotMessage v-for="(message, index) in chatLog.data"
            :message="message"
            :on-more-action="onSendMessage"
            :on-speak="onSpeak" />
          <div class="shortcuts"
            v-if="recommandMenu.isShowRecommandMenu">
            <ChatbotRecommandMenu :required-permission="recommandMenu.isSimpleRecommadMenu" :onPress="onSendMessage"
              :onMorePress="() => toggleMenu(true)"></ChatbotRecommandMenu>
          </div>
          <!-- Loading -->
          <ChatbotMessage
            v-if="isLoadingResponse"
            is-loading-response
            :message="loadingMessage" />
        </div>
        <div class="toolbar">
          <ChatbotInputBar :onSendMessage="onSendMessage"
            :onOpenMenu="() => toggleMenu(true)"></ChatbotInputBar>
        </div>

        <!-- 回饋 feedback -->
        <div class="floating-window">
          <div v-if="showReportFeedback" class="report-feedback">
            <div class="font-subtitle bold">已收到您的回饋，非常感謝您！</div>
            <div class="font-subtitle bold" style="cursor: pointer;" @click="toggleReportFeedback(false)">確認</div>
          </div>
        </div>

        <!-- 評分 -->
        <ChatbotRating ref="modalRating" />
      </div>

      <!-- menu -->
      <div v-if="showMenu" class="chatroom floating">
        <div class="navgation-bar">
          <v-icon variant="text" />
          <div class="title text-center">
            <h6 class="bold">服務總覽</h6>
          </div>
          <v-icon icon="close"
            color="rgba(0, 0, 0, 0.54)"
            variant="text"
            @click="toggleMenu(false)" />
        </div>
        <ChatbotMenu :onMenuPress="onMenuPress" />
      </div>

      <!-- Report -->
      <div v-if="showReport" class="chatroom floating">
        <div class="navgation-bar">
          <v-icon icon="arrow_back"
            color="rgba(0, 0, 0, 0.54)"
            variant="text"
            @click="toggleReport(false)" />
          <div class="title text-center">
            <h6 class="bold">意見回饋</h6>
          </div>
          <v-icon variant="text" />
        </div>
        <v-textarea class="report-field"
          variant="outlined"
          v-model="issue"
          :rules="[issueCountRule]"
          counter
          maxlength="500"
          no-resize
          placeholder="請回報你遇到的情境，或提供您的意見... (上限 500 字)" />
        <div class="toolbar">
          <v-btn color="primary" flat block size="large" @click="onSendIssue">傳送意見</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IMessage, sendMessage, report, CardMessage, IMessageFund } from '~/api/chatbot/index'
import { useSpeech } from '~/composables/chatbot/useSpeech'
import moment from 'moment'
import { useChatLog } from '~/composables/chatbot/useChatLog'
import { isExpanded, toggleEmbedChat, botUsedAt, recordBotUsed, allowOpenRating, recordRatingShowed } from '@/lib/chatbotWindow'

type ChatBotProps = {
  onBack?: () => void,
  type: 'normal' | 'full' | 'embed'
}
const props = defineProps<ChatBotProps>()
const { type } = toRefs(props)

const speech = useSpeech()

const onSpeak = (text: string) => {
  /**
   * To prevent apple limitation
   * https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html
   */
  new Audio().play()

  speech.value.speak(text)
}

const chatLog = useChatLog()

const send = async (text: string) => {
  const { data, error } = await sendMessage({ text })
  const msgs = (data.value?.entries || [])

  if (error.value) {
    chatLog.insert(({
      id: 0,
      publish_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      type: 'TEXT',
      from: 'BOT',
      text: error.value.status === 401
        || error.value.status === 440
        || error.value.message.match(/無效的登入權杖|登入權杖已過期/)
        ? `您的登入時效已過期，麻煩您[重新登入](https://www.ugfunds.com/member/signin)`
        : error.value.message,
    }))
    return
  }

  if (!msgs.length) {
    return
  }

  chatLog.insert(toRaw(msgs))
}

const recommandMenu = computed(() => {
  const lastMessage = chatLog.data.at(-1)
  const isShowRecommandMenu = chatLog.data.length === 1
    || (chatLog.data.length > 1
      && !!lastMessage
      && lastMessage.from === 'BOT'
      && lastMessage.type === 'TEXT'
      && !!lastMessage.text.match(/請問有什麼我可以協助您的事情呢|請問有什麼我可以為您服務|請參考服務選單|無法提供相關資訊|我只是個語言模型，目前我幫不上忙/)
    ) || (
      // Message Card No Funds
      chatLog.data.length > 1
      && !!lastMessage
      && lastMessage.from === 'BOT'
      && lastMessage.type !== 'TEXT'
      && !lastMessage.funds?.length
    )
  const isSimpleRecommadMenu = chatLog.data.length > 1
    && !!lastMessage
    && lastMessage.from === 'BOT'
    && lastMessage.type === 'TEXT'
    && !!lastMessage.text.match(/無法提供相關資訊|我只是個語言模型，目前我幫不上忙/)

  return {
    isShowRecommandMenu, isSimpleRecommadMenu
  }
})

const onMenuPress = async (message: string) => {
  toggleMenu(false)
  await onSendMessage(message)
}
const onSendMessage = async (text: string) => {
  const msg: IMessage = {
    id: 0,
    publish_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    type: 'TEXT',
    from: 'USER',
    text,
  }

  chatLog.insert(msg)
  await send(text).then(() => {
    recordBotUsed()
  })
}

const contentListRef = ref<HTMLDivElement>()
/** 漂浮 - 打開 */
onMounted(() => {
  contentListRef.value?.scrollTo(
    {
      top: contentListRef.value.scrollHeight,
      left: 0,
    }
  )
})

/** 經理人觀點 - 展開 */
watch(isExpanded, (expand) => {
  if (!expand) {
    return
  }
  contentListRef.value?.scrollTo(
    {
      top: contentListRef.value.scrollHeight,
      left: 0,
    }
  )
}, {
  immediate: true
})

/** 有新訊息 */
watch(() => chatLog.data?.length, (cur, pre) => {
  contentListRef.value?.scrollTo(
    {
      top: contentListRef.value.scrollHeight,
      left: 0,
      behavior: 'smooth'
    }
  )
}, {
  flush: 'post'
})

const loadingMessage: IMessage = { id: -9999, type: 'TEXT', from: 'BOT', publish_date: '', text: '' }
const loadingResponseTimeout = ref<number>()
const isLoadingResponse = ref(false)
// 使用者送出訊息後 {timeout} 秒內沒回覆會出現 loading
watch(chatLog.data, (newLogs) => {
  const timeout = 1500
  const lastMessage = newLogs.at(-1)
  if (loadingResponseTimeout.value) {
    isLoadingResponse.value = false
    clearTimeout(loadingResponseTimeout.value)
    loadingResponseTimeout.value = undefined
  }
  if (lastMessage?.from === 'USER') {
    loadingResponseTimeout.value = (setTimeout(() => {
      isLoadingResponse.value = true
      // Scroll to bottom
      setTimeout(() => {
        contentListRef.value?.scrollTo(
          {
            top: contentListRef.value.scrollHeight,
            left: 0,
            behavior: 'smooth'
          }
        )
      }, 0)
    }, timeout)) as unknown as number
  }
})

// 服務總覽
const showMenu = ref(false)
const toggleMenu = (action: boolean) => {
  showMenu.value = action
}

// 意見回饋
const showReport = ref(false)
const toggleReport = (action: boolean) => {
  showReport.value = action
}

const issue = ref('')
const issueCountRule = (value: string) => (value.length >= 10 && value.length <= 500) || '字數限制為 10-500 字元'
const onSendIssue = () => {
  report({ text: issue.value }).then(() => {
    toggleReport(false)
    setTimeout(() => {
      toggleReportFeedback(true)
    }, 500)
    issue.value = ''
  })
}

const showReportFeedback = ref(false)
const toggleReportFeedback = (action: boolean) => {
  showReportFeedback.value = action
}

const modalRating = ref()
const openRating = () => {
  modalRating.value.open()
}

watch(botUsedAt, (arr) => {
  // 評分觸發規則：累計使用 3 次，在第 3 次的第 1 個「回答」後發送
  if (arr.length >= 3 && allowOpenRating()) {
    setTimeout(() => {
      openRating()
      recordRatingShowed()
    }, 3000)
  }
})

</script>

<style scoped lang="stylus">
.chatbot-container
  background-color #fff
  border-radius 4px
  overflow hidden
  // 一般版本
  &.normal
    width 400px
    height clamp(340px, 700px,calc(100vh - 80px))
    border 1px solid $gray-lighten-4
    box-shadow 0px 1px 8px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)
  // 全屏
  &.full
    width 100%
    height 100%

  &.normal, &.full
    .navgation-bar
      display flex
      padding 12px 10px
      align-items center
      border-bottom 1px solid #ECECEC
      background-color $gray-lighten-5
      .title
        flex 1
        margin 0px 10px
      .subtitle
        color $black-0-42
    .toolbar
      padding 0px 16px 24px
  // 內嵌版本
  &.embed
    width 100%
    max-height 320px
    border 1px solid $black-0-12
    .navgation-bar
      display flex
      padding 8px 10px
      align-items center
      border-bottom 1px solid $black-0-12
      .title
        flex 1
        margin 0px 10px
    .content
      border-bottom 1px solid $black-0-12
      max-height 204px

.chatroom
  position relative
  display flex
  flex-direction column
  height 100%
  &.floating
    position absolute
    width 100%
    top 0
    left 0
    background-color #fff
  .content
    flex 1
    overflow-y auto
    padding 0 12px

.shortcuts
  margin 0px 60px 0px 32px

.report-field
  display flex
  flex-direction column
  margin 20px 16px
  :deep(.v-input__control)
    flex 1 1 0

// 漂浮視窗(殼)
.floating-window
  position absolute
  width 100%
  padding 0 10.5px
  bottom 104px
  left 0

// 回饋 feedback
.report-feedback
  display flex
  align-items center
  justify-content space-between
  color #fff
  width 100%
  padding 0 16px
  background-color $gray-darken-3
  box-shadow 0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 6px 12px 0px rgba(0, 0, 0, 0.08)
  border-radius 8px
  height 68px
  animation fadeInUp 0.6s

@keyframes fadeInUp
  0%
    opacity 0
    -webkit-transform translate3d(0,100%,0)
    transform translate3d(0,100%,0)
  to
    opacity 1
    -webkit-transform translateZ(0)
    transform translateZ(0)
</style>