export interface IRecorder {
  isRecording: boolean;
  permission: PermissionState;
  start: () => void;
  stop: () => void;
  cancel: () => void;
}

interface RecorderOptions {
  onStart?: () => void;
  onRecording?: (blob?: Blob) => void;
  onStop?: (blob?: Blob) => void;
  onError?: (error: Error) => void;
}

export class Recorder implements IRecorder {
  isRecording = false;
  permission = "prompt" as PermissionState;

  private mediaRecorder?: MediaRecorder;
  private onStart?: () => void;
  private onRecording?: (blob?: Blob) => void;
  private onStop?: (blob?: Blob) => void;
  private onError?: (error: Error) => void;

  constructor({
    onStart,
    onRecording,
    onStop,
    onError = console.error,
  }: RecorderOptions = {}) {
    this.onStart = onStart;
    this.onRecording = onRecording;
    this.onStop = onStop;
    this.onError = onError;
  }

  start() {
    if (this.permission === "denied") {
      console.warn("Please enabled microphone permission.");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        this.captureMicrophone(stream);
        this.onStart?.();
      })
      .catch((e) => {
        this.isRecording = false;

        if ((e?.message as string).includes("Permission denied")) {
          this.permission = "denied";
        }

        this.onError?.(e);
      });
  }

  stop() {
    this.isRecording = false;

    this.mediaRecorder?.stop();
    this.mediaRecorder = undefined;
  }

  cancel() {
    if (this.mediaRecorder) {
      this.mediaRecorder.onstop = null;
    }
    this.stop();
  }

  private captureMicrophone(stream: MediaStream) {
    this.isRecording = true;

    const mimeType = this.getMimeType();
    const recordedChunks: any[] = [];

    this.mediaRecorder = new MediaRecorder(stream, { mimeType });

    this.mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        this.onRecording?.(e.data);

        recordedChunks.push(e.data);
      }
    };

    this.mediaRecorder.onstop = (_mRecorder) => {
      if (mimeType === "audio/webm") {
        this.onStop?.(
          new Blob(recordedChunks, {
            type: mimeType,
          })
        );
      } else {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          if (!(e.target?.result instanceof ArrayBuffer)) {
            return;
          }
          const actx = new AudioContext();
          return actx
            .decodeAudioData(e.target.result)
            .then((arraybuffer) => this.resample(arraybuffer))
            .then((audio) => this.arraybuffer2wavebuffer(audio))
            .then((blob) => this.onStop?.(blob));
        };
        fileReader.readAsArrayBuffer(
          new Blob(recordedChunks, { type: mimeType })
        );
      }
    };

    this.mediaRecorder.start();
  }

  private getMimeType() {
    if (MediaRecorder.isTypeSupported("audio/webm")) {
      return "audio/webm";
    }
    if (!MediaRecorder.isTypeSupported("audio/mp4")) {
      console.error("No available mime type.");
    }
    return "audio/mp4";
  }

  private resample(audioBuffer: AudioBuffer, targetSampleRate = 16000) {
    const channel = audioBuffer.numberOfChannels;
    const samples =
      (audioBuffer.length * targetSampleRate) / audioBuffer.sampleRate;
    const offlineContext = new OfflineAudioContext(
      channel,
      samples,
      targetSampleRate
    );
    const bufferSource = offlineContext.createBufferSource();
    bufferSource.buffer = audioBuffer;

    bufferSource.connect(offlineContext.destination);
    bufferSource.start(0);

    return offlineContext.startRendering().then((abuffer) => {
      return {
        abuffer,
        len: abuffer.length,
      };
    });
  }

  private arraybuffer2wavebuffer({
    abuffer: audioBuffer,
    len,
  }: {
    abuffer: AudioBuffer;
    len: number;
  }) {
    const numOfChan = audioBuffer.numberOfChannels;
    const length = len * numOfChan * 2 + 44;
    const buf = new ArrayBuffer(length);
    const view = new DataView(buf);
    const channels = [];
    let offset = 0;
    let pos = 0;

    const setUint16 = (data: number) => {
      view.setUint16(pos, data, true);
      pos += 2;
    };

    const setUint32 = (data: number) => {
      view.setUint32(pos, data, true);
      pos += 4;
    };
    // write WAVE header
    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(audioBuffer.sampleRate);
    setUint32(audioBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    // write interleaved data
    for (let i = 0; i < audioBuffer.numberOfChannels; i += 1) {
      channels.push(audioBuffer.getChannelData(i));
    }

    while (pos < length) {
      for (let i = 0; i < numOfChan; i += 1) {
        // interleave channels
        let sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        // eslint-disable-next-line no-bitwise, max-len
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
        view.setInt16(pos, sample, true); // write 16-bit sample
        pos += 2;
      }
      offset += 1; // next source sample
    }
    return new Blob([buf], { type: "audio/wav" });
  }
}

export class ServerRecorder implements IRecorder {
  isRecording = false;
  permission = "denied" as PermissionState;
  start() {
    console.warn("There is no audio recorder.");
  }
  stop() {
    console.warn("There is no audio recorder.");
  }
  cancel() {
    console.warn("There is no audio recorder.");
  }
}
