<template>
  <div class="wrapper">
    <div class="containter"
      v-for="group in menu">
      <p class="group font-caption">
        {{ group.text }}
      </p>
      <div class="items">
        <v-btn v-for="category in group.categories"
          color="primary"
          @click="() => props.onMenuPress?.(category.message || category.text)"
          variant="outlined">
          {{ category.text }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMenu } from '~/composables/chatbot/useMenu'

type MenuProps = {
  onMenuPress?: (message: string) => void
}

const props = defineProps<MenuProps>()

const menu = useMenu()

</script>

<style scoped lang="stylus">

.wrapper
  overflow-y auto
  .containter
    border-bottom 1px solid $black-0-06
    .group
      margin 8px 16px
      color $black-0-6
    .items
      margin 12px 16px
      display flex
      flex-direction row
      flex-wrap wrap
      gap 12px

</style>
