<template>
  <ClientOnly>
    <v-overlay v-model="isVisible"
      width="100%"
      contained
      :persistent="!isRated"
      class="align-end justify-center">
      <div class="floating-window">
        <div class="rating-container"
          :class="isRated ? 'rated' : 'rating'">
          <template v-if="!isRated">
            <h6 class="blod text-center mb-6">滿意我們的智能客服嗎？</h6>
            <div class="mx-2">
              <div class="stars d-flex align-center justify-space-around mb-2">
                <v-btn v-for="i in 5"
                  @click="onStarClick(i)"
                  class="star"
                  variant="tonal"
                  size="small">
                  <v-icon icon="star" color="#FFF" />
                </v-btn>
              </div>
              <div class="d-flex align-center justify-space-between font-comment">
                <p>非常不滿意</p>
                <p>非常滿意</p>
              </div>
            </div>
            <v-icon class="close-button"
              @click="close"
              variant="text"
              icon="close"
              size="28" />
          </template>
          <template v-else>
            <Conffeti>
              <div>
                <div
                  class="d-flex align-center justify-center mb-2 bannner">
                  <v-icon icon="star" size="16" />
                  <h6 class="bold">感謝你的回饋</h6>
                  <v-icon icon="star" size="16" />
                </div>
                <p class="font-body-2 text-center">
                  每個回饋都能幫助我們變得更好
                </p>
              </div>
            </Conffeti>
          </template>
        </div>
      </div>
    </v-overlay>
  </ClientOnly>
</template>

<script setup lang="ts">
import { rating } from '~/api/chatbot';

const isVisible = ref(false)
const close = () => {
  isVisible.value = false
}

const open = () => {
  isVisible.value = true
}

const isRated = ref(false)
const onStarClick = async (score: number) => {
  await rating({ score })
  isRated.value = true
  setTimeout(() => {
    close()
  }, 2000)
}

defineExpose({
  isVisible,
  open,
  close
})
</script>


<style scoped lang="stylus">
.floating-window
  position absolute
  width 100%
  padding 0 10.5px
  bottom 104px
  left 0

.rating-container
  position relative
  border-radius 8px
  box-shadow 5px 4px 20px 0px rgba(0, 0, 0, 0.13)
  &.rating
    background #FFF
    padding 24px
    animation fadeInUp 0.3s
    .stars
      gap 8px
      .star
        background #CFD8DC
    .font-comment
      color $black-0-42
      font-family Microsoft JhengHei UI
      font-size 11px
      font-style normal
      font-weight 400
      line-height normal
    .close-button
      position absolute
      top 8px
      right 8px
      color $black-0-42
  &.rated
    background #263238
    color #FFF
    .bannner
      gap 8px

@keyframes fadeInUp
  0%
    opacity 0
    -webkit-transform translate3d(0,100%,0)
    transform translate3d(0,100%,0)
  to
    opacity 1
    -webkit-transform translateZ(0)
    transform translateZ(0)
</style>