<template>
  <div class="message-card-wrapper">
    <p class="stretch font-body-2 title">
      {{ fund.name }}－{{ fund.fund_class }}
    </p>
    <!-- Performance -->
    <template v-if="type === 'PERFORMANCE'">
      <div class="stretch content">
        <div class="row align-center">
          <h6 class="bold color-primary">{{ percentage(fund.perfs?.ytd, 1) }}</h6>
          <div class="font-caption color-grey">YTD</div>
        </div>
        <!-- Chart -->
        <div class="stretch chart"
          v-if="fund.performance">
          <ChatbotPerformanceChart :performance="fund.performance"></ChatbotPerformanceChart>
        </div>
        <div style="gap: 4px">
          <div class="row performance">
            <p class="font-body-2 bold">
              {{ percentage(fund.perfs?.m1, 1) }}
            </p>
            <p class="font-caption color-grey">
              {{ moment(fund.price_date).format('M') }}月
            </p>
          </div>
          <v-divider class="stretch" color="rgba(0, 0, 0, 0.06)" />
          <div class="row performance">
            <p class="font-body-2 bold">
              {{ percentage(fund.perfs?.stl, 1) }}
            </p>
            <p class="font-caption color-grey">
              成立至今
            </p>
          </div>
          <v-divider class="stretch" color="rgba(0, 0, 0, 0.06)" />
          <div class="row font-caption color-grey mt-2">
            <p>
              {{ moment(fund.price_date).format('YYYY-MM-DD') }}
            </p>
            <p>
              以正式淨值計算
            </p>
          </div>
        </div>
      </div>
    </template>
    <!-- AUM | NAV -->
    <template v-if="type === 'AUM' || type === 'NAV'">
      <div class="stretch content">
        <div class="row align-center">
          <h6 class="bold color-primary"
            v-if="type === 'NAV'">{{ fund.price }}</h6>
          <h6 class="bold color-primary" v-else>
            ${{ assetsSummary }} {{ $t(Words.HomeAumUnit).replace('美元', '') }}
          </h6>
          <p class="font-caption color-grey">
            ({{ fund.estimate ? '預估' : '正式' }})
          </p>
        </div>
        <div class="row font-caption color-grey">
          <p>
            {{ fund.currency || 'USD' }}
          </p>
          <p>
            {{ moment(fund.price_date).format('YYYY-MM-DD') }}
          </p>
        </div>
      </div>
    </template>
    <!-- DRAW_DOWN -->
    <template v-if="type === 'DRAW_DOWN'">
      <div class="stretch content">
        <div class="row align-center">
          <div class="flex-1-1">
            <p class="font-caption color-grey">
              最大連續回撤
            </p>
            <h6 class="bold color-primary">
              {{ typeof fund.draw_down === 'number' ? percentage(fund.draw_down, 2) : '-' }}
            </h6>
          </div>
          <div class="flex-1-1">
            <p class="font-caption color-grey">
              最大連續漲幅
            </p>
            <h6 class="bold color-primary">
              {{ typeof fund.increase === 'number' ? percentage(fund.increase, 2) : '-' }}
            </h6>
          </div>
        </div>
        <div class="row font-caption color-grey">
          以
          {{ moment(fund.start_date).format('YYYY-MM') }}
          {{ ' ~ ' }}
          {{ moment(fund.end_date).format('YYYY-MM') }}
          區間計算
        </div>
      </div>
    </template>
    <v-divider class="stretch"></v-divider>
    <div class="buttons">
      <v-btn v-for="more in moreButtons[type]"
        class="stretch"
        color="#F5F5F5"
        @click="onMoreAction?.(more.message)"
        variant="tonal"
        style="color: rgba(0,0,0,.87)">{{ more.text }}</v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment';
import { IMessageFund, MessageCardType } from '~/api/chatbot';

type MessageCardProps = {
  type: MessageCardType,
  fund: IMessageFund,
  onMoreAction?: (text: string) => void
}
const props = defineProps<MessageCardProps>()

const { english: enMode } = useViewMode()
const { percentage } = useFilter()

const assetsSummary = computed(() => {
  if (!props.fund.assets) return 0
  // 資料為百萬美元
  // B = 百萬美元 / 1000
  // 億 = 百萬美元 / 100
  if (enMode.value) return useFilter().round((props.fund.assets / 1000), 1)
  return useFilter().round((props.fund.assets / 100), 1)
})

const fundName = `${props.fund.name}${props.fund.fund_class}級別`
const moreButtons: Record<MessageCardType, Array<{ text: string, message: string }>> = {
  AUM: [
    {
      text: "查看報酬率",
      message: `查看${fundName}的報酬率`,
    },
    {
      text: "查看漲跌原因",
      message: `查看${fundName}漲跌原因`,
    },
    {
      text: "了解策略部位",
      message: `了解${fundName}的策略部位`,
    },
  ],
  NAV: [
    {
      text: "查看報酬率",
      message: `查看${fundName}的報酬率`,
    },
    {
      text: "查看漲跌原因",
      message: `查看${fundName}漲跌原因`,
    },
    {
      text: "了解策略部位",
      message: `了解${fundName}的策略部位`,
    },
  ],
  PERFORMANCE: [
    {
      text: "查看最新淨值",
      message: `查看${fundName}的淨值`,
    },
    {
      text: "查看漲跌原因",
      message: `查看${fundName}漲跌原因`,
    },
    {
      text: "了解策略部位",
      message: `了解${fundName}的策略部位`,
    },
  ],
  DRAW_DOWN: [
    {
      text: "查看報酬率",
      message: `查看${fundName}的報酬率`,
    },
    {
      text: "查看漲跌原因",
      message: `查看${fundName}漲跌原因`,
    },
    {
      text: "了解策略部位",
      message: `了解${fundName}的策略部位`,
    },
  ],
}
</script>

<style scoped lang="stylus">
.message-card-wrapper
  display flex
  padding 12px 16px
  flex-direction column
  justify-content center
  align-items center
  gap 12px
  border-radius 4px
  border 1px solid $black-0-12
  background #FFF
  flex 0 0 220px

  .row
    display flex
    flex-direction row
    // align-items center
    gap 8px
    &.performance
      justify-content space-between
      margin 4px 0px
  .stretch
    display flex
    flex-direction column
    align-self stretch
  .buttons
    display flex
    flex-direction column
    justify-content center
    align-items center
    gap 8px
    align-self stretch
  
.chart
  height 60px

.title
  color $black-0-87
.color-primary
  color $primary
.color-grey
  color $black-0-42

</style>
