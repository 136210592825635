<template>
  <!-- Loading Animation -->
  <div class="message"
    :class="message.from"
    v-if="isLoadingResponse">
    <v-avatar v-if="message.from === 'BOT'"
      class="avatar"
      size="24">
      <v-img class="image"
        :src="ChatBotAvatar" />
    </v-avatar>
    <div class="bubble"
      :class="message.from">
      <v-icon icon="api" class="loading"></v-icon>
    </div>
  </div>
  <template v-else>
    <!-- Text message -->
    <div class="message"
      :class="message.from"
      v-if="message.type === 'TEXT'">
      <v-avatar v-if="message.from === 'BOT'"
        class="avatar"
        size="24">
        <v-img class="image"
          :src="ChatBotAvatar" />
      </v-avatar>
      <div class="bubble"
        :class="message.from"
        v-html="parseMessage(message.text)">
      </div>
      <v-icon v-if="message.from === 'BOT'"
        icon="volume_up"
        color="rgba(0, 0, 0, 0.38)"
        size="28"
        @click="speak"
        variant="text" />
    </div>
    <template v-else-if="message.type === 'SELECTION'">
      <div class="message"
        :class="message.from">
        <v-avatar
          class="avatar"
          size="24">
          <v-img class="image"
            :src="ChatBotAvatar" />
        </v-avatar>
        <div class="bubble"
          :class="message.from">
          你想查看哪隻基金？
        </div>
      </div>
      <div class="selection" v-if="message.funds?.length">
        <div v-for="(fund, index) in message.funds" class="d-flex font-subtitle bold"
          @click="fund.text && onMoreAction?.(fund.text)">
          <v-badge
            color="primary"
            :content="index + 1"
            inline></v-badge>
          {{ fund.name }} - {{ fund.fund_class }}
        </div>
      </div>
    </template>
    <div class="row"
      v-else>
      <PerfectScroll v-if="message.funds?.length" class="card-wrap">
        <ChatbotMessageCard
          v-for="mFund in message.funds"
          class="mb-4"
          :on-more-action="onMoreAction"
          :type="message.type"
          :fund="mFund" />
      </PerfectScroll>
      <div v-else class="message no-permission"
        :class="message.from">
        <v-avatar class="avatar"
          size="24">
          <v-img class="image"
            :src="ChatBotAvatar" />
        </v-avatar>
        <div class="bubble"
          :class="message.from">
          抱歉，你尚無此訊息的權限，若有疑問，請洽客服 <a style="color: #1469CD" href="mailto:/info@ugfunds.com">info@ugfunds.com</a>。
          <br />
          <br />
          請參考其他服務選單：
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import ChatBotAvatar from '~/assets/images/chatbot.svg'
import { IMessage } from 'api/chatbot';


type MessageProps = {
  isLoadingResponse?: boolean;
  message: IMessage;
  onMoreAction?: (text: string) => void
  onSpeak?: (text: string) => void
}
const props = defineProps<MessageProps>()

const speak = () => {
  if (props.message.type === 'TEXT') {
    props.onSpeak?.(props.message.text)
  }
}

const parseMessage = (msg: string) => msg
  /** 空格 */
  .replace(/\n/g, '<br />')
  .replace(/\t/g, '&ensp;')
  /** 粗體 */
  .replace(/(?:\*\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*\*)/g, '<b>$1</b>')
  /** 斜體 */
  .replace(/(?:__)(?:(?!\s))((?:(?!\n|_).)+)(?:__)/g, '<i>$1</i>')
  /** 刪除線 */
  .replace(/(?:~~)(?:(?!\s))((?:(?!\n|~).)+)(?:~~)/g, '<s>$1</s>')
  /** 底線 */
  .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g, '<u>$1</u>')
  /** 連結 */
  .replace(/(?:\[)(?:(?!\s))((?:(?!\n|\[|\]).)+)(?:\])(?:\()(?:(?!\s))((?:(?!\n|\(|\)).)+)(?:\))/g, '<a style="color: #1469CD" href="$2">$1</a>')
  .replace(/(https:\/\/www.ugfunds.com)/g, window ? window.location.origin : '$1')

</script>

<style scoped lang="stylus">
.message
  display flex
  margin 20px 0px
  align-items flex-end
  gap 8px

  &.BOT
    margin-right 24px
  &.USER
    flex-direction: row;
    justify-content: flex-end;
    margin-left 24px
  &.no-permission
    margin 0px 24px 0px 0px

  .bubble
    padding 12px 16px
    &.USER
      border-radius: 24px 24px 0px 24px;
      background: linear-gradient(135deg, $primary 0%, $secondary 100%);
      color: $gray-lighten-5
    &.BOT
      border-radius: 24px 24px 24px 0px;
      background: $gray-lighten-4;
      color: $black-0-87

.row
  display flex
  align-items center
  gap 16px
  margin 20px 0px
.selection
  display flex
  margin 12px 0px
  padding-left 32px
  justify-content center
  flex-direction column
  gap 12px
  color $primary

.avatar
  border-radius 24px
  background #80CBC4
  .image
    width: 18px
    height: 12px
    flex-shrink: 0
.card-wrap
  display flex
  gap 12px
  flex 1

.loading
  animation: rotation 1600ms 0ms linear forwards infinite;
  background: linear-gradient(135deg, #27419F 0%, #A82262 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>