<template>
  <div class="wrapper">
    <v-btn rounded
      variant="tonal"
      v-for="shortcut in shortcutMenu"
      @click="() => onPress?.(shortcut.message || shortcut.text)"
      class="align-self-stretch">{{ shortcut.text }}
    </v-btn>
    <v-btn rounded
      @click="onMorePress"
      variant="tonal"
      append-icon="east"
      class="align-self-stretch">更多功能</v-btn>
  </div>
</template>

<script setup lang="ts">
import { useShortcutMenu } from '~/composables/chatbot/useMenu';

type RecommandMenuProps = {
  requiredPermission?: boolean
  onPress?: (message: string) => void
  onMorePress?: () => void
}

const props = defineProps<RecommandMenuProps>()

const shortcutMenu = useShortcutMenu(props.requiredPermission)

</script>
<style scoped lang="stylus">
.wrapper
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom 16px
</style>