import { Privilege } from "~/lib/privilege";
import { usePrivilege } from "~/composables/usePrivilege";

type Category = {
  text: string;
  message: string;
  permissions?: Privilege[];
};

type GroupedCategory = {
  text: string;
  categories: Array<Category>;
};

type Shortcutategory = Category & {
  icon?: string;
}

export function useMenu() {
  // TODO: messages
  const Menu: Array<GroupedCategory> = [
    {
      text: "基金資訊",
      categories: [
        {
          text: "最新淨值",
          message: "基金最新淨值",
          permissions: [Privilege.Prices],
        },
        {
          text: "報酬率",
          message: "我的基金的報酬率",
          permissions: [Privilege.Prices],
        },
        {
          text: "資產規模",
          message: "基金資產規模",
          permissions: [Privilege.Prices],
        },
        {
          text: "最大連續回撤/漲幅",
          message: "近一年的最大連續回撤及漲幅？",
          permissions: [Privilege.Prices],
        },
        {
          text: "查看漲跌原因",
          message: "查看我的基金漲跌的原因",
          permissions: [Privilege.Prices],
        },
        {
          text: "費用項目",
          message: "UG基金的費用有哪些",
          permissions: [Privilege.Prices],
        },
      ],
    },
    {
      text: "投資策略",
      categories: [
        {
          text: "未來投資策略",
          message: "基金接下來的投資策略",
        },
        {
          text: "基金特色",
          message: "UG基金的特色是什麼",
          permissions: [Privilege.Prices],
        },
        {
          text: "策略部位",
          message: "UG基金的策略部位",
          permissions: [Privilege.Prices],
        },
      ],
    },
    {
      text: "其他",
      categories: [
        {
          text: "獲獎歷史",
          message: "UG基金得獎紀錄",
        },
        {
          text: "贖回限制",
          message: "",
        },
        {
          text: "申購日期",
          message: "每月的申購贖回申請期限",
        },
        {
          text: "申贖規則",
          message: "UG 基金未來的申贖規則？",
        },
        {
          text: "查看庫存與獲利",
          message: "我的庫存與損益資訊",
          permissions: [Privilege.Assets],
        },
      ],
    },
    {
      text: "頁面索引",
      categories: [
        {
          text: "找庫存與損益",
          message: "我的庫存與損益去哪看",
          permissions: [Privilege.Assets],
        },
        {
          text: "找淨值數據",
          message: "基金淨值去哪找",
          permissions: [Privilege.Prices],
        },
        {
          text: "找月報與財報",
          message: "想看基金的月報與財報",
          permissions: [Privilege.Prices],
        },
        {
          text: "找市場觀點",
          message: "UG最新的市場觀點",
          permissions: [Privilege.Comments],
        },
        {
          text: "找獲獎紀錄",
          message: "想看UG得獎紀錄",
        },
        {
          text: "找工作機會",
          message: "UG有哪些工作職缺機會",
        },
      ],
    },
  ];

  return ref<Array<GroupedCategory>>(
    Menu
      /** Filter by permission */
      .map<GroupedCategory>((item) => {
        const { categories, text } = item;
        const filteredCategories = categories.filter(
          (c) => !c.permissions || usePrivilege(c.permissions)
        );
        return {
          text,
          categories: filteredCategories,
        };
      })
      /** Filter empty group */
      .filter((item) => !!item.categories.length)
  );
}

export function useShortcutMenu(requiredPermission?: boolean) {
  const Menu: Array<Shortcutategory> = [
    {
      text: "基金最新淨值",
      message: "",
      permissions: [Privilege.Prices],
    },
    {
      text: "基金報酬率",
      message: "我的基金的報酬率",
      permissions: [Privilege.Prices],
    },
    {
      text: "查看獲獎歷史",
      message: "",
    },
    {
      text: "未來投資策略",
      message: "基金接下來的投資策略",
    },
  ];

  return ref<Array<Shortcutategory>>(
    /** Filter by permission */
    Menu
    .filter(item => !requiredPermission || item.permissions)
    .filter((item) => !item.permissions || usePrivilege(item.permissions))
  );
}
