<template>
  <div>
    <div v-if="simple" class="simple-style">
      <input v-if="!recorder.isRecording"
        @keyup.enter="send"
        class="input font-body"
        type="text"
        placeholder="告訴我你想要甚麼？"
        density="comfortable"
        item-props
        menu-icon=""
        theme="light"
        v-model="text"
        variant="plain">
      <div v-else
        class="input recording-animate">
        <VueLottie
          :animationData="Recording"
          background="transparent"
          speed="1"
          style="width: 100%; height: 100%;"
          loop
          autoplay />
      </div>
      <!-- Right Icon -->
      <div class="icon-button ml-4">
        <v-btn v-if="!recorder.isRecording && !text"
          @click="startRecording"
          icon="mic" color="primary" size="36" variant="text" />
        <v-btn v-else
          @click="send"
          icon="send" color="primary" size="36" variant="text" />
      </div>
    </div>

    <div v-else class="default-style">
      <!-- Left Icon -->
      <div class="icon-button mr-4">
        <v-btn v-if="!recorder.isRecording" icon="menu" size="36" variant="text" @click="onOpenMenu" />
        <v-btn v-else icon="delete" variant="text" size="36" color="rgba(0, 0, 0, 0.54)" @click="cancelRecoding" />
      </div>
      <!-- Center input -->
      <input v-if="!recorder.isRecording"
        @keypress.enter.native="send"
        class="input font-body"
        type="text"
        placeholder="告訴我你想要甚麼？"
        density="comfortable"
        item-props
        menu-icon=""
        theme="light"
        v-model="text"
        variant="plain">
      <div v-else
        class="input recording-animate">
        <ClientOnly>
          <VueLottie
            :animationData="Recording"
            background="transparent"
            speed="1"
            style="width: 100%; "
            loop
            autoplay />
        </ClientOnly>
      </div>
      <!-- Right Icon -->
      <div class="icon-button ml-4">
        <v-btn v-if="hasAudioInput && !recorder.isRecording && !text"
          @click="startRecording"
          icon="mic" color="primary" size="36" variant="text" />
        <v-btn v-else
          @click="send"
          :disabled="!!text?.match(/^\s+$/) || (text?.length || 0) > 200"
          icon="send" color="primary" size="36" variant="text" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import Recording from '~/assets/animations/recording.json'

type InputBarProps = {
  simple?: boolean
  onOpenMenu?: () => void
  onSendMessage?: (text: string) => void
}
const props = defineProps<InputBarProps>()

import { Recorder, ServerRecorder, IRecorder } from '~/utils/recorder'
const recorder = ref<IRecorder>(new ServerRecorder())

const text = ref<string>()

const startRecording = () => { recorder.value.start() }
const cancelRecoding = () => { recorder.value.cancel() }

const onOpenMenu = () => {
  props.onOpenMenu?.()
}

const send = () => {
  const _text = text.value

  if (recorder.value.isRecording) {
    recorder.value.stop()
    return
  }

  if (!_text) {
    return
  }

  props.onSendMessage?.(_text)
  text.value = ''
  return
}

const hasAudioInput = ref(false)
onMounted(() => {
  /**
   * if navigator.mediaDevices is undefined, 
   */
  const initialRecorder = () => navigator.mediaDevices?.enumerateDevices().then(devices => {
    if (devices.find(({ kind }) => kind === 'audioinput')) {
      hasAudioInput.value = true

      const _recorder = new Recorder({
        onStop: (blob) => {
          if (!blob) {
            return
          }

          const form = new FormData();
          form.append("file", blob);
          fetch("/transcription", {
            method: "POST",
            body: form,
          })
            .then((r) => r.json())
            .then((d) => {
              props.onSendMessage?.(d.data)
            }).catch(console.error)
        }
      })
      recorder.value = _recorder
    } else {
      hasAudioInput.value = false
      recorder.value = new ServerRecorder()
    }
  })

  initialRecorder()

  navigator.mediaDevices?.addEventListener('devicechange', () => {
    console.log('onDeviceChange')
    initialRecorder()
  })
})

</script>

<style scoped lang="stylus">
.default-style
  display flex
  align-items center
  justify-content space-around
  padding 12px 16px
  border-radius 30px
  background $gray-lighten-4
  box-shadow 0px 1px 8px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)
.simple-style
  display flex
  align-items center
  padding 10px 16px

.input
  flex 1
  outline none

.icon-button
  height 100%
  display flex
  align-items center
  justify-content center

:deep(.recording-animate) .lottie-animation-container
  line-height 1
  
</style>
