<!-- 
    評分結束後的彩帶背景
    ref: https://codepen.io/agathaco/pen/wvMrXjz
-->
<template>
  <div ref="conffeti" class="conffeti-container">
    <slot></slot>
  </div>
</template>

<script setup>
const confettiShower = [];
const numConfettis = 20;
const conffeti = ref()
const colors = [
  "#f2abe7",
  "#9fa3ec",
  "#86d2e1 ",
  "#fec31e "
];

class Confetti {
  constructor(x, y, w, h, c) {
    this.w = Math.floor(Math.random() * 10 + 5);
    this.h = this.w*1;
    this.x = Math.floor(Math.random() * 100);
    this.y = Math.floor(Math.random() * 100);
    this.c = colors[Math.floor(Math.random() * colors.length)];
  }
  create() {
    const container = conffeti.value
    console.log(container)
    var newConfetti = '<div class="confetti" style="bottom:' + this.y +'%; left:' + this.x +'%;width:' +
      this.w +'px; height:' + this.h +'px;"><div class="rotate"><div class="askew" style="background-color:' + this.c + '"></div></div></div>';
    container.innerHTML+= newConfetti; 
    }
  };

function animateConfetti() {
  for (var i = 1; i <= numConfettis; i++) {
    var confetti = new Confetti();
    confetti.create();
  }
  var confettis = document.querySelectorAll('.confetti');
  for (var i = 0; i < confettis.length; i++) {
    var opacity = Math.random() + 0.1;
    var animated = confettis[i].animate([
      { transform: 'translate3d(0,0,0)', opacity: opacity },
      { transform: 'translate3d(20vw,100vh,0)', opacity: 1 }
    ], {
      duration: Math.random() * 3000 + 3000,
      iterations: Infinity,
      delay: -(Math.random() * 5000)
    });
   confettiShower.push(animated);
  }
}

onMounted(() => {
  animateConfetti()
})

</script>

<style lang="stylus" scoped>
.conffeti-container
  position relative
  padding 24px
  width 100%
  height 100%
  overflow hidden

//confetti
:deep(.confetti)
  position absolute
  z-index 999
  .rotate 
    animation driftRotate 1s infinite both ease-in-out
    width 100%
    height 100%
  .askew 
    width 100%
    height 100%
    animation drift 1s infinite alternate both ease-in-out


@keyframes drift {
  0% {
    transform: skewY(10deg) translate3d(-250%, 0, 0)
  }
  100% {
    transform: skewY(-10deg) translate3d(250%, 0, 0)
  }
}
@keyframes driftRotate {
  0% {
    transform: rotateX(0) 
  }
  100% {
    transform: rotateX(360deg)
  }
}

// animate random參數
.confetti:nth-child(0) .askew 
  animation-duration 1.46s
  animation-delay -1.3432s

.confetti:nth-child(0) .rotate 
  animation-duration 1.26s
  animation-delay -0.2646s

.confetti:nth-child(1) .askew 
  animation-duration 1.12s
  animation-delay -0.0784s

.confetti:nth-child(1) .rotate 
  animation-duration 1.92s
  animation-delay -0.9792s

.confetti:nth-child(2) .askew 
  animation-duration 1.87s
  animation-delay -0.8602s

.confetti:nth-child(2) .rotate 
  animation-duration 1.57s
  animation-delay -0.9106s

.confetti:nth-child(3) .askew 
  animation-duration 1.28s
  animation-delay -0.4992s

.confetti:nth-child(3) .rotate 
  animation-duration 1.94s
  animation-delay -1.261s

.confetti:nth-child(4) .askew 
  animation-duration 1.3s
  animation-delay -0.676s

.confetti:nth-child(4) .rotate 
  animation-duration 1.45s
  animation-delay -1.1745s

.confetti:nth-child(5) .askew 
  animation-duration 1.93s
  animation-delay -0.772s

.confetti:nth-child(5) .rotate 
  animation-duration 1.43s
  animation-delay -0.4719s

.confetti:nth-child(6) .askew 
  animation-duration 1.39s
  animation-delay -1.3483s

.confetti:nth-child(6) .rotate 
  animation-duration 1.89s
  animation-delay -0.4158s

.confetti:nth-child(7) .askew 
  animation-duration 1.64s
  animation-delay -0.656s

.confetti:nth-child(7) .rotate 
  animation-duration 1.1s
  animation-delay -0.209s

.confetti:nth-child(8) .askew 
  animation-duration 1.92s
  animation-delay -0.3264s

.confetti:nth-child(8) .rotate 
  animation-duration 1.12s
  animation-delay -0.4032s

.confetti:nth-child(9) .askew 
  animation-duration 1.16s
  animation-delay -0.464s

.confetti:nth-child(9) .rotate 
  animation-duration 1.29s
  animation-delay -1.0836s

.confetti:nth-child(10) .askew 
  animation-duration 1.01s
  animation-delay -0.2222s

.confetti:nth-child(10) .rotate 
  animation-duration 1.3s
  animation-delay -1.027s

.confetti:nth-child(11) .askew 
  animation-duration 1.5s
  animation-delay -0.285s

.confetti:nth-child(11) .rotate 
  animation-duration 1.76s
  animation-delay -1.32s

.confetti:nth-child(12) .askew 
  animation-duration 1.55s
  animation-delay -0.0155s

.confetti:nth-child(12) .rotate 
  animation-duration 1.73s
  animation-delay -0.2422s

.confetti:nth-child(13) .askew 
  animation-duration 1.08s
  animation-delay -0.9504s

.confetti:nth-child(13) .rotate 
  animation-duration 1.81s
  animation-delay -1.2127s

.confetti:nth-child(14) .askew 
  animation-duration 1.01s
  animation-delay -0.2828s

.confetti:nth-child(14) .rotate 
  animation-duration 1.96s
  animation-delay -0.9996s

.confetti:nth-child(15) .askew 
  animation-duration 1.81s
  animation-delay -1.2489s

.confetti:nth-child(15) .rotate 
  animation-duration 1.94s
  animation-delay -1.6296s

.confetti:nth-child(16) .askew 
  animation-duration 1.96s
  animation-delay -1.7836s

.confetti:nth-child(16) .rotate 
  animation-duration 1.66s
  animation-delay -0.5146s

.confetti:nth-child(17) .askew 
  animation-duration 1.23s
  animation-delay -0.3198s

.confetti:nth-child(17) .rotate 
  animation-duration 1.38s
  animation-delay -0.4692s

.confetti:nth-child(18) .askew 
  animation-duration 1.8s
  animation-delay -1.656s

.confetti:nth-child(18) .rotate 
  animation-duration 1.11s
  animation-delay -0.9879s

.confetti:nth-child(19) .askew 
  animation-duration 1.72s
  animation-delay -0.9116s

.confetti:nth-child(19) .rotate 
  animation-duration 1.84s
  animation-delay -0.6624s

</style>
